import React from 'react';
import { Link } from 'react-router-dom';

function FeaturesBlocks() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200 dark:border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h1 className="h2 font-red-hat-display mb-4">From web to mobile we craft beautiful user experiences. </h1>
            <p className="text-xl text-gray-600 dark:text-gray-400">Our Unified Digital Experience solution ensures all touchpoints are on-purpose, meaningful and right on time.</p>
          </div>

          {/* Items */}
          <div className="max-w-xs mx-auto sm:max-w-none md:max-w-xl lg:max-w-none grid sm:grid-cols-2 gap-4 lg:gap-6 lg:grid-cols-4" data-aos-id-featbl>

            {/* 1st item */}
            <Link className="flex flex-col p-5 group text-white bg-gradient-to-tr from-teal-500 to-teal-400 dark:to-teal-500 shadow-2xl" to="/mobile-app-development"  data-aos="fade-down" data-aos-anchor="[data-aos-id-featbl]">
            <img width="60" src={require('../images/app.svg').default}/>
              <div className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1 mt-4">Mobile App Development</div>
              <div className="flex-grow opacity-80 mb-4">Modern and focused.</div>
              
            </Link>

            {/* 2nd item */}
            <Link className="flex flex-col p-5 group text-white bg-gradient-to-tr from-purple-500 to-purple-400 dark:to-purple-500 shadow-2xl" to="/web-app-development" data-aos="fade-down" data-aos-anchor="[data-aos-id-featbl]" data-aos-delay="100">
            <img width="90" src={require('../images/web.svg').default}/>
              <div className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1 mt-4">Web App Development</div>
              <div className="flex-grow opacity-80 mb-4">Blazing fast integrated sites.</div>
             
              </Link>

            {/* 3rd item */}
            <Link className="flex flex-col p-5 group text-white bg-gradient-to-tr from-indigo-500 to-indigo-400 dark:to-indigo-500 shadow-2xl" to="/unified-digital-experience" data-aos="fade-down" data-aos-anchor="[data-aos-id-featbl]" data-aos-delay="200">
            <img width="80" src={require('../images/udx.svg').default}/>
              <div className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1 mt-4">UDX</div>
              <div className="flex-grow opacity-80 mb-4">Unified Digital Experience for forward-thinking companies.</div>
              
              </Link>

            {/* 4th item */}
            <Link className="flex flex-col p-5 group text-white bg-gradient-to-tr from-pink-500 to-pink-400 dark:to-pink-500 shadow-2xl" to="/digital-marketing" data-aos="fade-down" data-aos-anchor="[data-aos-id-featbl]" data-aos-delay="300">
            <img width="90" src={require('../images/tv.svg').default}/>
              <div className="font-red-hat-display text-xl font-extrabold tracking-tighter mb-1 mt-4">Digital Marketing</div>
              <div className="flex-grow opacity-80 mb-4">Get found...and noticed.</div>
              
              </Link>

          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
