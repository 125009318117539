import React from 'react';

function Clients() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-red-hat-display mb-4">Technologies that just fit.</h2>
            <p className="text-xl text-gray-600 dark:text-gray-400">Every project is different and we match the tech stack to the business goals and rules set by our clients.</p>
          </div>
          {/* Items */}
          <div className="grid gap-2 grid-cols-2 md:grid-cols-4" data-aos-id-clients>

            {/* Item */}
            <div className="flex items-center justify-center h-24 border border-gray-200 dark:border-gray-800 p-2" data-aos="fade-up" data-aos-anchor="[data-aos-id-clients]">
            <img width="80" src={require('../images/react.svg').default}/>
            </div>

            {/* Item */}
            <div className="flex items-center justify-center h-24 border border-gray-200 dark:border-gray-800 p-2" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-clients]">
            <img width="65" src={require('../images/flutter.svg').default}/>
            </div>

            {/* Item */}
            <div className="flex items-center justify-center h-24 border border-gray-200 dark:border-gray-800 p-2" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-clients]">
            <img width="100" src={require('../images/node.svg').default}/>
            </div>

            {/* Item */}
            <div className="flex items-center justify-center h-24 border border-gray-200 dark:border-gray-800 p-2" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-clients]">
            <img width="70" src={require('../images/vue.svg').default}/>
            </div>

            {/* Item */}
            <div className="flex items-center justify-center h-24 border border-gray-200 dark:border-gray-800 p-2" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-clients]">
            <img width="70" src={require('../images/angular.svg').default}/>
            </div>

            {/* Item */}
            <div className="flex items-center justify-center h-24 border border-gray-200 dark:border-gray-800 p-2" data-aos="fade-up" data-aos-delay="500" data-aos-anchor="[data-aos-id-clients]">
            <img width="70" src={require('../images/html.svg').default}/>
            </div>

            {/* Item */}
            <div className="flex items-center justify-center h-24 border border-gray-200 dark:border-gray-800 p-2" data-aos="fade-up" data-aos-delay="600" data-aos-anchor="[data-aos-id-clients]">
            <img width="70" src={require('../images/css.svg').default}/>
            </div>

            {/* Item */}
            <div className="flex items-center justify-center h-24 border border-gray-200 dark:border-gray-800 p-2" data-aos="fade-up" data-aos-delay="700" data-aos-anchor="[data-aos-id-clients]">
            <img width="70" src={require('../images/js.svg').default}/>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;