import React from 'react';

function FeaturesZigzag() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mt-20 mx-auto text-center pb-12 md:pb-16">
            {/*<div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">Reach goals that matter</div>*/}
            <h1 className="h2 mb-4">Make a Payment</h1>
            <p className="text-xl text-gray-400">We appreciate your business!</p>
          </div>

          {/* Items */}
          <div className="grid gap-20">

            {/* 1st item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-12" data-aos="fade-right">
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                <div>
  <div><div style="
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 259px;
  background: transparent;
  border: 0px solid rgba(0, 0, 0, 0.1);
 
  border-radius: 10px;
  font-family: SQ Market, Helvetica, Arial, sans-serif;
  ">
  <div style="padding: 20px;">
  <a target="_blank" data-url="https://square.link/u/S5Sa2rsN?src=embd" href="https://square.link/u/S5Sa2rsN?src=embed" style="
    display: inline-block;
    font-size: 18px;
    line-height: 48px;
    height: 48px;
    color: #ffffff;
    min-width: 212px;
    background-color: #000000;
    text-align: center;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1) inset;
    
  ">Make a Payment</a>
  </div>
  
</div>
</div>
</div>

  
</div>
               
              </div>
            </div>

            

            

          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesZigzag;